.loginPage form {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.submit {
    height: 2rem;
    width: 6rem;
    position: relative;
    align-self: center;
    margin-bottom: 1rem;
}

.register {
    height: 2rem;
    width: 8rem;
    position: relative;
    align-self: center;
    margin-bottom: 1rem;
}

.editWarranty {
    height: 2rem;
    width: 6rem;
    position: relative;
    align-self: center;
    margin-bottom: 1rem;
}

input[type=number].phone::-webkit-inner-spin-button,
input[type=number].phone::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.back {
    margin: 1rem;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.parts {
    margin: "1rem"
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    z-index: 1000;
}

h4 {
    margin-bottom: 0.5rem;
}