.label {
    margin-top: 1rem;
    font-size: 14px;
}

.column {
    display: flex;
    flex-direction: column;
}

.column p {
    font-size: medium;
    font-weight: bold;
    padding-top: 1rem;
}

.back {
    margin: 1rem;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.sort {
    cursor: pointer;
}

.parts {
    margin: "1rem"
}

.modal-content h3 {
    margin-bottom: -0.5rem;
}

.jobImage {
    resize: none;
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
    padding-bottom: 1rem;
    padding-right: 1rem;
    z-index: 0;
}

/* .quantity input[type=number] {
    width: 175px
} */

.findingsDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.findingsInput {
    width: stretch;
}

.partsDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0.5rem;
}

.partsSelect {
    flex: 1;
    min-width: 0;
    margin-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.partsSelect option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.quantityDiv {
    margin-bottom: 0.5rem;
}

.image-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

@media (max-width: 612px) {
    .mobileButton {
        height: 26.25px;
    }
}