.margin {
    margin: 1rem;
}

.header {
    padding-left: 1rem;
    padding-top: 1rem;
}

.column {
    display: flex;
    flex-direction: column;
}

.column p {
    font-size: medium;
    font-weight: bold;
    padding-top: 1rem;
}

.back {
    margin: 1rem;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.sort {
    cursor: pointer;
}

.parts {
    margin: "1rem"
}

table {
    border-collapse: collapse;
    width: 100%;
    background-color: #f5f5f5;
}

.jobTable {
    display: flex;
    align-items: center;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-size: 13px;
    background-color: white;
}

table tr:first-child th:first-child {
    border-top-left-radius: 0.5rem;
    border: 1px solid #f5f5f5;
}

table tr:first-child th:last-child {
    border-top-right-radius: 0.5rem;
    border: 1px solid #f5f5f5;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
}

input[type=number].phone::-webkit-inner-spin-button,
input[type=number].phone::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.head th {
    background-color: black;
    color: white;
}

.modal-close-btn {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: black;
}

.modal-content h3 {
    margin-bottom: -0.5rem;
}

.jobImage {
    resize: none;
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
    padding-bottom: 1rem;
    padding-right: 1rem;
    z-index: 0;
}

.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.departmentidTable {
    border-collapse: collapse;
    margin-left: 1rem;
}

.departmentidButton {
    margin: 1rem;
}

.settingsPage td,
.settingsPage th {
    padding: 4px
}

.settingsPage table {
    width: fit-content;
}

.settings-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.settings-column {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding-left: 1rem;
}

.settings-column button,
.settings-column select {
    font-size: 13px;
}

.jobStatus button {
    background-color: black;
    color: white;
    border-radius: 0.25rem;
    border: 1.5px solid black;
    cursor: pointer;
}

.jobStatus button:hover {
    background-color: #333;
    border: 1.5px solid #333;
    color: #fff;
}

.fitTable {
    width: stretch;
}

.mobileScroll {
    display: block;
    overflow-x: auto;
}

.table-wrapper {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
}