@media (max-width: 932px) and (orientation: portrait) {
    .filterIsland {
        margin: 1rem;
        background-color: white;
        border-radius: 1rem;
    }

    .filter {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .filter div {
        margin: 0.5rem;
    }

    .filter label {
        margin-right: 0.25rem;
        font-size: 14px;
    }

    .filter p {
        margin: 0;
    }

    .date {
        display: flex;
        align-items: baseline;
        margin: 0 !important;
    }

    .filters {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
    }

    .clear-all-container {
        grid-column: 1 / -1;
        grid-row: 3;
        margin-top: 0.5rem;
        margin-left: 1.5rem;
        margin-bottom: 1rem !important;
    }

    .filter-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mediaButton {
        height: 125%;
    }
}

@media (max-width: 932px) and (orientation: landscape) {
    .filterIsland {
        margin: 1rem;
        background-color: white;
        border-radius: 1rem;
    }

    .filter {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .filter div {
        margin: 0.5rem;
    }

    .filter label {
        margin-right: 0.25rem;
    }

    .filter p {
        margin: 0;
    }

    .date {
        display: flex;
        align-items: baseline;
        margin: 0 !important;
    }

    .filters {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto 1fr;
    }

    .clear-all-container {
        grid-column: 1 / -1;
        grid-row: 3;
        margin-top: 0.5rem;
        margin-left: 1.5rem;
        margin-bottom: 1rem !important;
    }

    .filter-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mediaButton {
        height: 125%;
    }
}

@media (min-width: 932px) {
    .filterIsland {
        margin: 1rem;
        background-color: white;
        border-radius: 1rem;
    }

    .filter {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .filter div {
        margin: 0.5rem;
    }

    .filter label {
        margin-right: 0.5rem;
    }

    .filter p {
        margin: 0;
    }

    .date {
        display: flex;
        align-items: baseline;
        margin: 0 !important;
    }

    .dateFlexAlign {
        display: flex;
        align-items: baseline;
    }

    .filters {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }

    .clear-all-container {
        grid-column: 1;
        grid-row: 2;
        margin-top: 0.5rem;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .filter-column {
        display: flex;
        flex-direction: column;
    }

    .filterIsland br {
        display: none;
    }

    .filter-group-1 label {
        width: 63px;
        display: inline-block;
        text-align: right;
    }

    .filter-group-2 label {
        width: 120.646px;
        display: inline-block;
        text-align: right;
    }

    .filter-group-3 label {
        width: 86.59px;
        display: inline-block;
        text-align: right;
    }

    .filter-group-4 label {
        width: 38.74px;
        display: inline-block;
        text-align: right;
    }
}

.dateInput {
    width: auto !important;
}

.filter-column input {
    width: 192px;
}

.filter-column select {
    width: 200px;
}