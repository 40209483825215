ul {
    padding-inline-start: 1rem;
}

@media (max-width: 612px) and (orientation: landscape) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
    }
}

@media (max-width: 612px) and (orientation: portrait) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
    }
}

@media (min-width: 612px) and (orientation: landscape) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 612px) and (orientation: portrait) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 702px) and (orientation: landscape) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 702px) and (orientation: portrait) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 802px) and (orientation: landscape) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 350px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 802px) and (orientation: portrait) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 350px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 1000px) and (orientation: landscape) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 350px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input {
        font-size: medium;
    }

    .form-column select {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}

@media (min-width: 1185px) {
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        z-index: 1000;
    }

    .header {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        margin-bottom: 2rem;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        background-color: white;
        border-radius: 1rem;
        height: 100%;
        margin: auto;
    }

    .form-column label {
        margin-top: 1rem;
        font-size: medium;
    }

    .form-column input,
    .form-column select,
    .form-column textarea {
        font-size: medium;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .newJobImage {
        resize: none;
        max-height: 75vh;
        max-width: 75vw;
        height: auto;
        width: auto;
        padding-bottom: 1rem;
        z-index: 0;
    }

    .clearImage {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .uploadImage {
        margin: 2.5rem;
    }
}