@media (max-width: 801px) and (orientation: portrait) {

    .dashboard {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: black;
        flex: 1;
    }

    .dashboard button {
        margin: 1rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .background-color {
        height: 100vh;
        background-color: #f5f5f5;
    }

    .logo-image-dashboard {
        position: relative;
        align-self: center;
        width: 35%;
        margin-top: 1rem;
    }

    .dashboard .logout-button {
        margin: 0.5rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: center;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .profile {
        margin-top: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .profile-info {
        display: flex;
        align-items: center;
    }

    .profile-fullname {
        color: white;
    }
}

@media (max-width: 801px) and (orientation: landscape) {

    .dashboard {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: black;
        flex: 1;
    }

    .dashboard button {
        margin: 1rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .background-color {
        background-color: #f5f5f5;
    }

    .logo-image-dashboard {
        position: relative;
        align-self: center;
        width: 20%;
        margin-top: 1rem;
    }

    .dashboard .logout-button {
        margin: 0.5rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: center;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .profile {
        margin-top: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .profile-fullname {
        color: white;
    }
}

@media (min-width: 801px) and (orientation: landscape) {

    .dashboard {
        width: 30%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: black;
        display: flex;
        flex-direction: column;
    }

    .dashboard button {
        margin: 1rem;
        margin-left: 3rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .background-color {
        min-height: 100vh;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
    }

    .logo-image-dashboard {
        position: relative;
        align-self: center;
        max-width: 50%;
        margin-top: 1rem;
    }

    .dashboard .logout-button {
        margin: 0.5rem;
        margin-left: 3rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .profile {
        margin-top: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .profile-fullname {
        color: white;
        padding-left: 3rem;
    }
}

@media (min-width: 975px) and (orientation: landscape) {

    .dashboard {
        width: 25%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: black;
        display: flex;
        flex-direction: column;
    }

    .dashboard button {
        margin: 1rem;
        margin-left: 3rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .background-color {
        min-height: 100vh;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
    }

    .logo-image-dashboard {
        position: relative;
        align-self: center;
        max-width: 50%;
        margin-top: 1rem;
    }

    .dashboard .logout-button {
        margin: 0.5rem;
        margin-left: 3rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .profile {
        margin-top: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .profile-fullname {
        color: white;
        padding-left: 3rem;
    }
}

@media (min-width: 1185px) {

    .dashboard {
        width: 20%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: black;
        display: flex;
        flex-direction: column;
    }

    .dashboard button {
        margin: 1rem;
        margin-left: 3rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .background-color {
        min-height: 100vh;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
    }

    .logo-image-dashboard {
        position: relative;
        align-self: center;
        max-width: 50%;
        margin-top: 1rem;
    }

    .dashboard .logout-button {
        margin: 0.5rem;
        margin-left: 3rem;
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        cursor: pointer;
        color: white;
    }

    .profile {
        margin-top: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .profile-fullname {
        color: white;
        padding-left: 3rem;
    }
}

.profile-info {
    display: flex;
    align-items: center;
}

.profile-username {
    color: white;
    padding-left: 0.5rem;
    font-size: small;
}