.Text {
    text-align: center;
}

.nav-links {
    list-style-type: none;
    padding: 1rem;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-Thin.ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url("../fonts/LibreFranklin-Black.ttf");
    font-weight: 900;
    font-style: normal;
}

header,
h1,
h2,
h3,
h4,
h5,
h6,
body,
label,
p {
    font-family: 'CustomFont', sans-serif;
}

header {
    font-weight: 900;
}

h1 {
    font-weight: 900;
}

h2 {
    font-weight: 800;
}

h3 {
    font-weight: 700;
}

h4 {
    font-weight: 600;
}

h5 {
    font-weight: 500;
}

h6 {
    font-weight: 400;
}

p,
label,
body {
    font-weight: 400;
}

button,
input[type="button"],
input[type="submit"] {
    font-family: 'CustomFont', sans-serif;
    font-weight: 400;
    background-color: black;
    color: white;
    border-radius: 0.25rem;
    border: 1.5px solid black;
    cursor: pointer;
}

button:hover {
    background-color: #333;
    border: 1.5px solid #333;
    color: #fff;
}

input::placeholder,
textarea::placeholder {
    font-family: 'CustomFont', sans-serif;
    font-weight: 400;
}

select,
input {
    font-family: 'CustomFont', sans-serif;
    font-weight: 400;
}

table {
    font-family: 'CustomFont', sans-serif;
    font-weight: 400;
}

th {
    font-weight: 700;
}

td {
    font-weight: 400;
}