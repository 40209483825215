.form-elements>* {
    margin-bottom: 1rem;
}

.form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-container form {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.background-image {
    height: 100vh;
    object-fit: cover;
    background-position: center;
}

.logo-image {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    max-width: 300px;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    background-color: #f5f5f5;
}

.login-container input {
    border-radius: 0.5rem;
    height: 1.5rem;
    padding-left: 0.5rem;
    border: 1.5px solid gray;
}

.login-container button {
    border: none;
    appearance: none;
    background: transparent;
    text-align: right;
    padding: 0;
    width: fit-content;
    text-decoration: underline;
    margin-left: auto;
    cursor: pointer;
    color: black;
}

@media (max-width: 767px) {

    /* smartphones, iPhone, portrait 480x320 phones */
    .form-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 251, 246, 0.8);
        padding: 2rem;
        border-radius: 8px;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-container form {
        display: flex;
        flex-direction: column;
        width: 200px;
    }

    .background-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .logo-image {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 200px;
    }

    .login-container {
        /* display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #f5f5f5; */
        position: relative;
        height: 100vh;
        overflow: hidden;
    }

    .login-container input {
        border-radius: 0.5rem;
        height: 1.5rem;
        padding-left: 0.5rem;
        border: 1.5px solid gray;
    }

    .login-container button {
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        text-decoration: underline;
        margin-left: auto;
        cursor: pointer;
        color: black;
    }
}

@media (max-width: 767px) and (orientation: landscape) {

    /* Tablet landscape styles */
    .form-container {
        position: absolute;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 251, 246, 0.8);
        padding: 2rem;
        border-radius: 8px;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-container form {
        display: flex;
        flex-direction: column;
        width: 200px;
    }

    .background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .logo-image {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        max-width: 200px;
    }

    .login-container {
        position: relative;
        height: 100vh;
        overflow: hidden;
    }

    .login-container input {
        border-radius: 0.5rem;
        height: 1.5rem;
        padding-left: 0.5rem;
        border: 1.5px solid gray;
    }

    .login-container button {
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        text-decoration: underline;
        margin-left: auto;
        cursor: pointer;
        color: black;
    }
}

@media (min-width:768px) {

    /* big landscape tablets, laptops, and desktops */
    .form-container {
        margin-left: 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-elements>* {
        margin-bottom: 1rem;
    }

    .form-container form {
        display: flex;
        flex-direction: column;
        width: 200px;
    }

    .background-image {
        width: 60%;
        object-fit: cover;
        background-position: center;
    }

    .logo-image {
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        max-width: 300px;
    }

    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        background-color: #f5f5f5;
    }

    .login-container input {
        border-radius: 0.5rem;
        height: 1.5rem;
        padding-left: 0.5rem;
        border: 1.5px solid gray;
    }

    .login-container button {
        border: none;
        appearance: none;
        background: transparent;
        text-align: right;
        padding: 0;
        width: fit-content;
        text-decoration: underline;
        margin-left: auto;
        cursor: pointer;
        color: black;
    }
}